<template>
  <div class="report-wrapper">
    <div
      class="pane mx-2 mt-2 mb-1"
      :class="dataFetched ? 'white' : ''"
    >
      <v-row
        class="heading-row align-center ma-0"
        :class="{ 'pb-2': $vuetify.breakpoint.smAndDown }"
      >
        <v-col
          class="col-lg-3 col-sm-6 col-6"
          :class="{'pa-0': $vuetify.breakpoint.smAndDown }"
        >
          <report-parameters-chooser />
        </v-col>
        <v-col
          v-if="Object.keys(getReportParams).length !== 0"
          class="drivers-col col-lg-6"
        >
          <div class="mx-auto text-left" style="width: fit-content">
            <div>
              {{ getSelectedReportType.reportName }}
            </div>
            <div>
              {{ $t('satellite-tracking/report.period_from_to')
                .replace(':date_from', formatSqlDate(getReportParams.dateFrom))
                .replace(':date_to', formatSqlDate(getReportParams.dateTo)) }}
            </div>
            <div>
              {{ $t('satellite-tracking/report.report_for_persons') }} {{ getSelectedPersons.length ? ' - ' + personNames : '' }}
            </div>
          </div>
        </v-col>
        <v-col class="col-lg-3">
          <v-row
            no-gutters
            class="justify-center"
          >
            <download-report-button
              v-if="getSelectedPersons.length && getReportParams"
              type="driver"
              route="driver-report-new/export"
              :data="getReportParams"
              :disabled="Object.keys(getReportParams).length === 0"
              :file-name="$t('satellite-tracking/report.report_for_persons') + ' - ' + personNames"
            />
          </v-row>
        </v-col>
      </v-row>
      <transition name="fade">
        <v-row
          v-if="!dataFetched"
          key="data-not-fetched"
          class="image-row justify-center py-10 ma-0"
        >
          <img
            :src="'/img/icons/report-vehicle-full.png'"
            alt="No pic"
          >
        </v-row>
      </transition>
    </div>
    <v-row
      v-if="!dataFetched"
      class="pictogram-row justify-center"
      no-gutters
    >
      <img
        :src="'/img/icons/table-pictogram.png'"
        alt="No pic"
        class="pictogram"
      >
    </v-row>
    <div
      v-if="dataFetched"
      class="pane white mx-2 mb-2"
      :style="{ overflowY: 'auto' }"
    >
      <div v-if="getSelectedReportType.reportType === 'summary'">
        <report-table
          :report-data="fetchedReport.data"
          :report-summary="fetchedReport.summary"
          :report-column-config="fetchedReport.reportColumnConfig"
          :table-config="fetchedReport.tableConfig"
        />
      </div>
      <v-row v-if="getSelectedReportType.reportType === 'reportByDay'">
        <v-col>
          <v-card>
            <v-tabs
              v-model="tab"
              height="40"
              grow
            >
              <v-tab
                v-for="person in getSelectedPersons"
                :key="person.id"
                @click="setSelectedPerson(person)"
              >
                {{ person.full_name }}
              </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
              <v-tab-item
                v-for="person in getSelectedPersons"
                :key="person.id"
                class="py-3"
              >
                <report-table
                  :key="person.id"
                  :report-data="fetchedReport.data[person.id]"
                  :report-summary="fetchedReport.summary[person.id]"
                  :report-column-config="fetchedReport.reportColumnConfig"
                  :table-config="fetchedReport.tableConfig"
                />
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <v-overlay :value="loading">
      <v-progress-circular
        indeterminate
        size="60"
      />
    </v-overlay>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import ReportParametersChooser from '../components/ReportParametersChooser'
import ReportTable from '../components/ReportTable'
import DownloadReportButton from '@/modules/base-module/reports/components/DownloadReportButton'
import { formatSqlDate } from '@/global/services/helpers/dates'
const { mapGetters, mapActions } = createNamespacedHelpers('base/reportsByPerson')

export default {
  name: 'ReportByPerson',
  components: {
    ReportParametersChooser,
    ReportTable,
    DownloadReportButton
  },
  data () {
    return {
      isMobile: false,
      showImage: true,
      chartType: 'line',
      tab: null
    }
  },
  computed: {
    ...mapGetters(['fetchedReport', 'getSelectedReportType', 'getReportParams', 'getSelectedPersons', 'loading']),
    dataFetched () {
      return 'data' in this.fetchedReport
    },
    personNames () {
      return this.getSelectedPersons.map(person => person.full_name).join(', ')
    }
  },
  watch: {
    getSelectedReportType (getSelectedReportType) {
      this.showImage = getSelectedReportType.reportType !== 'summary'
    }
  },
  beforeDestroy () {
    this.resetState()

    if (typeof window === 'undefined') return
    window.removeEventListener('resize', this.onResize, { passive: true })
  },
  mounted () {
    this.fetchReportsMetaData()

    this.onResize()
    window.addEventListener('resize', this.onResize, { passive: true })
  },
  methods: {
    formatSqlDate,
    ...mapActions(['resetState', 'fetchReportsMetaData', 'setSelectedPerson']),
    onResize () {
      this.isMobile = window.innerWidth <= 480
    }
  }
}
</script>

<style lang="scss">
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s ease;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.report-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 64px);
  overflow: hidden;
  border: 1px solid #ccc;
  > .pane {
    padding: 15px;
    &.white:first-child {
      flex-shrink: 0;
    }

    .heading-row {
      .drivers-col {
        font-size: 13px;
        font-weight: 500;
        line-height: 1rem;
        letter-spacing: 0.0125em;
        font-family: "Roboto", sans-serif;
      }
    }
    .image-row {
      height: 90%;
      img {
        opacity: 0.75;
        height: 100%;
        width: auto;
      }
    }
    ~ .pane {
      border-top: 1px solid #ccc;
    }
  }
  .pictogram {
    opacity: 0.05;
    height: 100%;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .v-input__slot {
    justify-content: center !important;
  }
  .v-data-table__wrapper {
    table {
      tbody {
        tr {
          td {
            border-radius: 1px;
            &:nth-of-type(odd):not(.summary-cell) {
              background-color: rgba(0, 0, 0, .025);
            }
            div {
              padding: 0 0.5rem;
            }
          }
        }
      }
    }
  }
  .report-wrapper {
    height: auto;
    width: 100%;
    .pane {
      overflow: scroll;
      .heading-row {
        flex-direction: column;
        .drivers-col {
          font-size: 1rem;
          line-height: 1.5rem;
          letter-spacing: 0;
        }
      }
      .image-row {
        img {
          height: auto;
          width: 100%;
        }
      }
    }
    .pictogram-row {
      .pictogram {
        height: auto;
      }
    }
  }
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th,
.v-data-footer {
  font-size: 0.75rem !important;
}

@media (max-width: 480px) {
  .report-wrapper {
    height: auto;
    width: 100%;
  }
}
</style>
