<template>
  <v-row
    class="align-center parameters-chooser-row"
    no-gutters
  >
    <v-autocomplete
      v-model="selectedReport"
      outlined
      dense
      hide-details
      :items="reportTypes"
      :placeholder="$t('satellite-tracking/report.select_report_type')"
      item-value="reportType"
      item-text="reportName"
      return-object
      class="pr-2"
      @change="callReportParamsDialog($event)"
    />
    <v-btn
      v-if="selectedReport"
      color="secondary"
      icon
      @click="callReportParamsDialog(selectedReport)"
    >
      <v-icon>mdi-pencil</v-icon>
    </v-btn>
    <params-dialog
      :dialog="paramsDialog"
      :report-details="reportDetails"
      @close="closeParametersDialog"
    />
  </v-row>
</template>

<script>
import ReportParametersDialog from './ReportParametersDialog'
import { createNamespacedHelpers } from 'vuex'
const { mapGetters, mapActions } = createNamespacedHelpers('base/reportsByPerson')

export default {
  name: 'ReportParametersChooser',
  components: {
    paramsDialog: ReportParametersDialog
  },
  data () {
    return {
      paramsDialog: false,
      reportDetails: {}
    }
  },
  computed: {
    ...mapGetters(['getReportsMetaData', 'getSelectedReportType']),
    selectedReport: {
      get: function () {
        return this.getSelectedReportType
      },
      set: function (value) {
        this.setSelectedReportType(value)
      }
    },
    reportTypes () {
      if ('reportTypes' in this.getReportsMetaData) {
        return this.getReportsMetaData.reportTypes
      }
      else return []
    }
  },
  methods: {
    ...mapActions(['setSelectedReportType']),
    closeParametersDialog () {
      this.paramsDialog = false
    },
    callReportParamsDialog (reportObj) {
      this.paramsDialog = true
      this.reportDetails = reportObj
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .v-select__slot > input {
  font-size: 12px;
  font-weight: 500;
  line-height: 1rem;
}

@media (max-width: 768px) {
  .parameters-chooser-row {
    flex-direction: row;
    flex-wrap: nowrap;
  }
}
</style>
