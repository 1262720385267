<template>
  <v-row
    no-gutters
    class="mx-4"
  >
    <v-data-table
      v-if="reportData.length"
      :headers="dataTableHeaders"
      :items="reportData"
      :items-per-page="tableConfig.itemsPerPage || 10"
      :footer-props="{
        itemsPerPageOptions: config.dataTableItemsPerPageOptions
      }"
      class="elevation-1 flex"
      dense
    >
      <template
        v-if="reportSummary.length > 0"
        slot="body.append"
      >
        <tr v-if="!isMobile">
          <td
            v-for="(sum, index) in reportSummary"
            :key="sum + '-' + index"
            class="summary-cell"
          >
            {{ sum }}
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-row>
</template>

<script>
import { forEach, upperFirst } from 'lodash'
import dataTableConfig from '@/global/common/dataTableConfig'

export default {
  name: 'ReportTable',
  props: {
    reportData: {
      type: Array,
      required: true,
      default: () => ([])
    },
    reportSummary: {
      type: Array,
      required: true,
      default: () => ([])
    },
    reportColumnConfig: {
      type: Object,
      required: true,
      default: () => ({})
    },
    tableConfig: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },
  data () {
    return {
      isMobile: false,
      config: dataTableConfig
    }
  },
  computed: {
    dataTableHeaders () {
      const headers = []

      forEach(this.reportColumnConfig, function (column, key) {
        headers.push({
          text: upperFirst(column.label),
          value: key
        })
      })

      return headers
    }
  },
  mounted () {
    this.onResize()
    window.addEventListener('resize', this.onResize, { passive: true })
  },
  beforeDestroy () {
    if (typeof window === 'undefined') return
    window.removeEventListener('resize', this.onResize, { passive: true })
  },
  methods: {
    onResize () {
      this.isMobile = window.innerWidth < 600
    }
  }
}
</script>

<style scoped lang="scss">
@media (max-width: 768px) {
  .v-data-table {
    > .v-data-table__wrapper {
      table {
        tbody {
          display: flex;
          flex: 1;
          tr {
            display: flex;
            td {
              display: flex;
              &.summary-cell {
                flex-grow: 1;
              }
            }
          }
        }
      }
    }
  }
}
</style>
