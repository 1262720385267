<template>
  <v-dialog
    v-model="showDialog"
    persistent
    :max-width="isMobile || isTablet ? '479px' : '600px'"
    @keydown.enter.prevent="enterButtonPressed"
    @keydown.esc.prevent="escapeButtonPressed"
  >
    <v-card>
      <v-card-title class="text-break">
        <span class="parameter-heading">
          {{ $t('satellite-tracking/report.choose_parameters_for_report') + ': "' + reportDetails.reportName + '"' }}
        </span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <report-dates
            v-if="reportDetails.reportType === 'reportByDay'"
            :date-menus="dateMenus"
            :reset-date-to="resetDateTo"
            @date-to-reset="resetDateTo = false"
            @set-date-from="event => setDateFrom(event)"
            @set-date-to="event => setDateTo(event)"
            @date-menus="event => setDateMenus(event)"
          />
          <report-date-times
            v-else
            :date-time-format="'DD.MM.YYYY HH:mm'"
            :reset-date-to="resetDateTo"
            @set-date-from="event => setDateFrom(event)"
            @set-date-to="event => setDateTo(event)"
            @date-to-reset="resetDateTo = false"
            @date-time-menus="event => setDateTimeMenus(event)"
            @escape-enter-button-pressed="escapeButtonPressed"
          />
          <v-row>
            <v-col>
              <person-picker
                key="multiplePickerSelect"
                v-model="selectedPersons"
                :max-select="maxSelect"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          small
          color="secondary"
          @click="$emit('close', reportDetails.reportType)"
        >
          {{ $t('satellite-tracking/report.close') }}
        </v-btn>
        <v-btn
          small
          color="primary"
          @click="getReport"
        >
          {{ $t('satellite-tracking/report.show_report') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ReportDates from '@/modules/base-module/reports/report-by-person/components/ReportDates'
import ReportDateTimes from '@/modules/base-module/reports/report-by-person/components/ReportDateTimes'
import PersonPicker from '@/modules/people-module/components/PersonPicker'
import withoutWatchersMixin from '@/global/mixins/withoutWatchersMixin'
import { isEmpty } from 'lodash'
import { createNamespacedHelpers } from 'vuex'
import { formatSqlDate, formatSqlDateTime } from '@/global/services/helpers/dates'
import dayjs from 'dayjs'
const { mapGetters: mapGettersReports, mapActions: mapActionsReports } = createNamespacedHelpers('base/reportsByPerson')

export default {
  name: 'ReportParametersDialog',

  components: {
    PersonPicker,
    ReportDates,
    ReportDateTimes
  },

  mixins: [withoutWatchersMixin],

  props: {
    dialog: {
      type: Boolean,
      required: true
    },
    reportDetails: {
      type: Object,
      required: true,
      default () {
        return { reportName: null, reportType: null }
      }
    }
  },

  data () {
    return {
      isMobile: false,
      isTablet: false,
      dateFrom: null,
      dateTo: null,
      resetDateTo: false,
      dateMenus: {
        dateFrom: false,
        dateTo: false
      },
      dateTimeMenus: {
        dateFromMenu: false,
        dateToMenu: false
      },
      maxSelect: 3
    }
  },

  computed: {
    ...mapGettersReports([
      'fetchedReport',
      'getSelectedPersons',
      'getSelectedReportType'
    ]),

    showDialog () {
      return this.dialog
    },

    selectedPersons: {
      get () {
        return this.getSelectedPersons
      },
      set (persons) {
        this.setSelectedPersons(persons)
      }
    }
  },

  watch: {
    dateFrom (newDate) {
      const from = dayjs(newDate)
      const to = dayjs(this.dateTo)

      if (from.add(31, 'day').isBefore(to)) {
        this.$withoutWatchers(() => {
          this.dateTo = null
          this.dateToFormatted = null
        })
        this.resetDateTo = true
      }
    },

    reportDetails: {
      deep: true,
      handler (reportDetails) {
        if (reportDetails.reportType === 'reportByDay') {
          this.dateFromFormatted = formatSqlDate(this.dateFrom)
          this.dateToFormatted = formatSqlDate(this.dateTo)
        }
        else {
          this.dateFromFormatted = formatSqlDateTime(this.dateFrom)
          this.dateToFormatted = formatSqlDateTime(this.dateTo)
        }
      }
    },

    dateTo (dateTo) {
      this.dateToFormatted = (this.reportDetails.reportType === 'reportByDay')
        ? formatSqlDate(dateTo)
        : formatSqlDateTime(dateTo)
    }
  },

  created () {
    this.dateFrom = dayjs().subtract(1, 'day').format('YYYY-MM-DD')
    this.dateTo = dayjs().format('YYYY-MM-DD')
  },

  mounted () {
    this.onResize()
    window.addEventListener('resize', this.onResize, { passive: true })
  },

  beforeDestroy () {
    this.resetState()

    if (typeof window === 'undefined') return
    window.removeEventListener('resize', this.onResize, { passive: true })
  },

  methods: {
    ...mapActionsReports([
      'resetState',
      'fetchReport',
      'setReportParams',
      'setSelectedPerson',
      'setSelectedPersons'
    ]),

    isEmpty,

    setDateFrom (event) {
      this.dateFrom = event
    },

    setDateTo (event) {
      this.dateTo = event
    },

    setDateMenus (event) {
      this.dateMenus = event
    },

    setDateTimeMenus (event) {
      setTimeout(() => (this.dateTimeMenus = event), 50)
    },

    enterButtonPressed () {
      if (this.reportDetails.reportType === 'reportByDay') {
        const someDatePickerIsOpen = Object.values(this.dateMenus).includes(true)
        someDatePickerIsOpen ? this.resetDateMenus() : this.getReport()
      }
      else {
        const someDateTimePickerIsOpen = Object.values(this.dateTimeMenus).includes(true)
        someDateTimePickerIsOpen ? this.resetDateTimeMenus() : this.getReport()
      }
    },

    escapeButtonPressed () {
      if (this.reportDetails.reportType === 'reportByDay') {
        const someDatePickerIsOpen = Object.values(this.dateMenus).includes(true)
        if (!someDatePickerIsOpen) {
          this.$emit('close', this.reportDetails.reportType)
        }
      }
      else {
        const someDateTimePickerIsOpen = Object.values(this.dateTimeMenus).includes(true)
        if (!someDateTimePickerIsOpen) {
          this.$emit('close', this.reportDetails.reportType)
        }
      }
    },

    getReport () {
      if (!this.selectedPersons.length || !this.dateFrom || !this.dateTo) {
        alert(this.$t('satellite-tracking/report.you_should_choose_all_parameters'))
      }
      else {
        this.setReportParams(this.getParams())
        this.setSelectedPerson(this.getSelectedPersons[0])
        this.fetchReport()
      }
    },

    getParams () {
      this.$emit('close', this.reportDetails.reportType)
      return {
        reportType: this.reportDetails.reportType,
        personId: this.getSelectedPersons.map(person => person.id),
        dateFrom: this.dateFrom,
        dateTo: this.dateTo
      }
    },

    resetDateMenus () {
      this.dateMenus = {
        dateFromMenu: false,
        dateToMenu: false
      }
    },

    resetDateTimeMenus () {
      this.dateTimeMenus = {
        dateFromMenu: false,
        dateToMenu: false
      }
    },

    onResize () {
      this.isMobile = window.innerWidth <= 480
      this.isTablet = window.innerWidth > 480 && window.innerWidth <= 768
    }
  }
}
</script>

<style>
@media (max-width: 768px) {
  .v-application .parameter-heading {
    font-size: 1.33rem !important;
  }
}
</style>
